<template>
    <div class="body">
        <div class="container" :class="isFooter? 'with-footer': ''">
            <div class="content" v-if="task">
                <div class="monitor">
                    <div class="process-total">
                        任务总进度
                        <div class="process-num">{{task.task_process.speed_num}}%</div>
                    </div>
                    <div class="task-ford">
                        <div class="ford-item">
                            <div class="">指派人</div>
                            <strong>管理员</strong>
                        </div>
                        <div class="ford-item">
                            <div class="">学习进度</div>
                            <strong>{{task.task_process.speed_num}}%</strong>
                        </div>
                        <div class="ford-item">
                            <div class="">考试及格</div>
                            <strong>{{task.task_process.status == 1?1:0}}/1场</strong>
                        </div>
                    </div>
                    <div class="task-ford-desc">
                        合格标准：学习进度完成100% 考试及格
                    </div>
                </div>
                <div class="task-sumury">
                    <div class="title">{{task.info.name}}</div>
                    <div class="p1 light-color">
                        <strong>{{task.info.credit}}学分</strong>
                        {{task.task_process.start_time}}~{{task.task_process.end_time}}
                    </div>
                    <div class="p2">培训目的：{{task.info.name}}</div>
                </div>
                <div class="separator-line full-width"></div>
                <div class="section-lession">
                    <div class="lession-header">课程</div>
                    <div class="lession-chap-list">
                        <div class="chap" v-for="(lesson, index) in task.lessions" 
                          :key="lesson.id" @click="toLesson(index)">
                            <div class="chap-info">
                                <div class="top">
                                    <img :src="getLessonIcon(lesson.lesson_type)" >
                                    {{getLessonTypeName(lesson.lesson_type)}}
                                </div>
                                <div class="text-ellipsis">
                                    {{lesson.name}}
                                </div>
                            </div>
                            <div class="chap-process">
                                <van-progress :percentage="getPercent(lesson)" pivot-text="" />                    
                                <div>{{getPercent(lesson)}}%</div>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="separator-line full-width" v-if="task.info.examid"></div>
                <div class="section-exam" v-if="task.info.examid">
                    <div class="section-header">
                        {{task.info.exam.title}}
                        <span class="light-color">
                            （共{{task.info.exam.qs_num}}道题,计时{{task.info.exam.minute}}分钟）</span>
                    </div>
                    <!-- <van-button disabled type="info">去考试</van-button> -->
                    <div class="qu">
                        <span class="qu-btn" @click="toExam" v-if="task.task_process.status == 0">去考试 
                        </span>
                        <span class="qu-btn" @click="toResul" v-if="task.task_process.status != 0">查看结果
                        </span>
                    </div>
                 </div>
                 <div class="footer full-width" v-if="task.task_process.status == 0">
                     <van-button type="info" @click="toTask" v-if="task.task_process.status ==0">去做任务</van-button>
                    
                 </div>
             </div>
        </div>
    </div>
</template>

<script>
    import {toast, checkLogin} from '@/utils/common.js';
    import { getTaskDetail } from '@/api/task.js';
    import { mapState } from 'vuex';
    var id, tsid;
    export default{
        data(){
           return{
               task: null,
               src: require('@/assets/images/video-s.png'),
               tsid: '',
               tid: '',
               isFooter: false,
           } 
        },
        activated() {
            id = this.tid = this.$route.query.id; // 任务id2
            tsid = this.tsid = this.$route.query.tsid;// 任务结果id
            checkLogin().then(()=> {
                this.getDetail(id, tsid);
            })
            this.hideTab();
          
        },
        computed: mapState(['token']),
        methods:{
            getDetail(id, tsid){
                getTaskDetail(id, tsid).then((ret)=>{
                    this.task = ret.data;
                    if(this.task.task_process.status ==0){
                        this.isFooter = true;
                    }else{
                        this.isFooter = false;
                    }
                });
            },
            toLesson(index){
                var item = this.task.lessions[index];
                var lid = item['lid'];
                if (item.lesson_type == 0) {
                    this.$router.push(`/video?id=${id}&tsid=${tsid}&cur=${index}&lid=${lid}`);
                } else if(item.lesson_type == 1 || item.lesson_type == 2){
                    this.$router.push(`/toffice?id=${id}&tsid=${tsid}&cur=${index}&lid=${lid}`);
                }else if(item.lesson_type == 3 ){
                    this.$router.push(`/tpdf?id=${id}&tsid=${tsid}&cur=${index}&lid=${lid}`);
                }
            },
            toTask(){
                // 如果视频没看完就去看视频，或者就去做题
                if(this.task.task_process.speed_num ==100){
                    this.toExam();
                }else{
                   this.toLesson(0); 
                }
            },
            toExam(){
                if(!this.task){
                    return;
                }
                if(this.task.task_process.speed_num !=100){
                    toast('抱歉,你的课程尚未看完，不能前去考试', 'error');
                    return;
                }
                this.$router.push(`/exam?tid=${this.tid}&examid=${this.task.info.examid}`);
            },
            toResul(){
                if(!this.task){
                    return;
                }
                this.$router.push(`/result?tid=${this.tid}&examid=${this.task.info.examid}`);
            },
            getPercent(lesson){
                if(lesson.lesson_type != 0){
                    if(lesson.speed_num == 0){
                        return 0;
                    }else{
                        return 100;
                    }
                }
                var div = Math.floor(Number(lesson.speed_num)*10000/Number(lesson.total_num));
                return div/100;
            },
            hideTab(){
                //console.log(document.querySelector('#vantab'));
                document.querySelector('#vantab').classList.add('tab-hide');
            },
            getLessonIcon(lessonType){
                console.log(lessonType);
                switch (lessonType){
                    case 0:
                        return require('@/assets/images/video-s.png');
                    case 1:
                        return require('@/assets/images/ppt-s.png');
                    case 2:
                        return require('@/assets/images/word-s.png');
                    case 3:
                        return require('@/assets/images/pdf-s.png');
                    default:
                        return '';
                }
            },
            getLessonTypeName(lessonType){
                switch (lessonType){
                    case 0:
                        return '视频';
                    case 1:
                        return 'ppt';
                    case 2:
                        return 'word';
                    case 3:
                        return 'pdf';
                    default:
                        return '其他';
                }
            },
        },
    }
</script>

<style scoped>
    .container{
       font-size: 14px; 
       bottom: 0;
       max-height: 100%;
    }
    .with-footer{
        bottom: 50px !important;
    }
    .content{
        height: 100%;
        overflow: auto;
    }
    .monitor{
        height: 26.5rem;
        margin-top: 20px;
        background-color: #465ecc;
        color: #fff;
        border-radius: 5px;
        overflow: auto;
        text-align: center;
    }
    .process-total{
        margin-top: 3.4rem;
    }
    .process-total .process-num{
        font-size: 30px;
        font-weight: bold;
    }
    .task-ford{
        display: flex;
        width: 31rem;
        margin: 2.5rem auto 1.5rem;
        padding: 10px 0;
        background-color: #566ed0;
        border-radius: 5px;
        line-height: 1.8;
    }
    .task-ford .ford-item{
        width: 33.33%;
    }
    .task-sumury{
        line-height: 1.5;
        font-size: 16px;
        margin: 15px 0;
    }
    .task-sumury .title{
        font-size: 26px;
        font-weight: bold;
    }
    .task-sumury strong{
        color: #f29901;
    }
    .separator-line{
        height: 8px;
        background-color: #f2f2f2;
        margin: 17px 0 23px auto;
    }
    .section-lession{
        font-size: 16px;
    }
    .section-lession .lession-header{
        border-bottom: 1px solid #f2f2f2;
        margin-bottom: 1.2rem;
        padding-bottom: 1.8rem;
    }
    .section-lession .chap{
        display: flex;
        background-color: #f7f8fa;
        border-radius: 3px;
        padding: 1.5rem;
        margin-bottom: 1rem;
    }
    .section-lession .chap-info{
        width: 27rem;
    }
    .section-lession .chap-process{
        width: 6rem;
        display: flex;
        justify-content: center;
        align-items: flex-start;
        color: #566ed0;
        flex-direction: column;
        font-size: 14px;
    }
    
    .section-lession .chap .top{
        display: flex;
        align-items: center;
    }
    .section-lession .chap img{
        width: 1.6rem;
        height: 1.7rem;
        padding-right: 5px;
    }
    .section-lession .chap .van-progress{
        display: block;
        width: 100%;
        margin-bottom: 2px;
    }
    .section-exam{
        font-size: 16px;
        padding-bottom: 20px;
    }
    .section-exam .section-header{
        /* padding-bottom: 1.8rem; */
    }
    .section-exam .light-color{
        font-size: 14px;
    }
    .qu{
        /* text-decoration: underline; */
        text-align: center;
        padding-right: 25px;
        font-size: 16px;
    }
    .qu .qu-btn{
        border-bottom: 1px solid #465ecc;
        color: #465ecc;
    }
    .qu img{
        width: 30px;
        height: 30px;
        vertical-align: middle;
        
    }
    .footer{
        position: fixed;
        width: 100vw;
        height: 4.5rem;
        bottom: 5px;
        border-top: 1px solid #e8e8e8;
    }
    .footer button{
        margin-top: 0.5rem;
        height: 3.5rem;
        width: 34.5rem;
        line-height: 3.5rem;
        font-size: 1.4rem;
        background-color: #465ecc;
        margin-left: 1.5rem;
    }
</style>